<template>
  <div id="app">
    <Header />
    <!-- 使用动态类或内联样式控制 router-view 的 margin-left -->
    <div :style="contentStyle">
      <router-view />
      <!-- 在这里渲染匹配的组件 -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      windowWidth: window.innerWidth, // 初始化屏幕宽度
    };
  },
  computed: {
    contentStyle() {
      // 判断是否为小屏幕，小于 789px 时给 router-view 留出左侧空间
      return this.windowWidth < 789 ? { marginLeft: "3.5rem" } : {};
    },
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh; 
  text-align: center;
  color: #2c3e50;
  /* min-height: 100vh; */
  /* padding-top: 60px; */
}
.content-area {
  padding-bottom: 3rem; /* 确保内容不被 footer 遮挡 */
}
footer {
  position: absolute;
  bottom: -1.2rem;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  #app {
    /* padding-top: 50px; */
  }
  footer {
  position: absolute;
  bottom: -1.2rem;
  font-size: 0.4rem;
  width: 100%;
  text-align: center;
}
}
</style>
