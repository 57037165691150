<template>
  <div class="page">
    <div class="login">
      <el-form :model="form" ref="form" @submit.native.prevent>
        <!-- 用户名 -->
        <el-form-item prop="username" :rules="usernameRules">
          <el-input v-model="form.username" placeholder="请输入用户名">
            <template slot="prepend">
              <i class="el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item prop="password" :rules="passwordRules">
          <el-input v-model="form.password" type="password" placeholder="请输入密码" show-password>
            <template slot="prepend">
              <i class="el-icon-lock"></i>
            </template>
          </el-input>
        </el-form-item>

        <!-- 验证码 -->
        <el-form-item prop="captcha" :rules="captchaRules">
          <el-row :gutter="10">
            <el-col :span="16">
              <el-input v-model="form.captcha" placeholder="请输入验证码"></el-input>
            </el-col>
            <el-col :span="8">
              <!-- 图形验证码 -->
              <canvas ref="captchaCanvas" class="captcha-img" @click="generateCaptcha"></canvas>
            </el-col>
          </el-row>
        </el-form-item>

        <!-- 注册和忘记密码链接 -->
        <el-form-item class="footer-links">
          <el-button type="text" @click="goToRegister">注册账号</el-button>
          <el-button type="text" @click="goToForgotPassword">忘记密码</el-button>
        </el-form-item>
        <!-- 登录按钮 -->
        <el-form-item>
          <el-button type="primary" class="login-btn" @click="submitForm">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
        captcha: "",
      },
      captchaText: "", // 用于存储生成的验证码文本
      // 用户名校验规则
      usernameRules: [{ required: true, message: "请输入用户名", trigger: "blur" }],
      // 密码校验规则
      passwordRules: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 6, max: 20, message: "密码长度应在6到20个字符之间", trigger: "blur" },
      ],
      // 验证码校验规则
      captchaRules: [{ required: true, message: "请输入验证码", trigger: "blur" }],
    };
  },
  methods: {
    // 生成图形验证码
    generateCaptcha() {
      const canvas = this.$refs.captchaCanvas;
      const ctx = canvas.getContext("2d");

      // 设置画布大小
      canvas.width = 100;
      canvas.height = 40;

      // 随机生成4个字符
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let captcha = "";
      for (let i = 0; i < 4; i++) {
        captcha += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      // 保存验证码文本用于验证
      this.captchaText = captcha;

      // 清除画布
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // 设置背景颜色
      ctx.fillStyle = "#f5f5f5";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 绘制验证码文本
      ctx.font = "20px Arial";
      ctx.fillStyle = "#000";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(captcha, canvas.width / 2, canvas.height / 2);

      // 绘制一些干扰线条
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.strokeStyle = "#ccc";
        ctx.stroke();
      }
    },
    // 提交表单
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 校验验证码
          if (this.form.captcha !== this.captchaText) {
            this.$message.error("验证码错误");
            return false;
          }

          // 提交表单
          this.$message.success("登录成功！");
          this.$router.push({ name: "Home" });
        } else {
          this.$message.error("请检查表单填写是否正确");
          return false;
        }
      });
    },
    // 跳转到注册页面
    goToRegister() {
      this.$router.push({ name: "Register" });
    },
    // 跳转到忘记密码页面
    goToForgotPassword() {
      return
      // this.$router.push({ name: "ForgotPassword" });
    },
  },
  mounted() {
    // 初始化时生成验证码
    this.generateCaptcha();
  },
};
</script>

<style scoped lang="scss">
.page {
  display: flex;
  justify-content: center;
  // height: 70vh;
}

.login {
  width: 26rem;
  height: 30rem;
  border-radius: 0.8rem;
  border: 1px solid #dac3c3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.05);
  margin-top: 8rem;
  padding: 2rem;
  box-sizing: border-box;

  .captcha-img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .login-btn {
    width: 100%;
    background-color: #16baaa;
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 14px;
  }
}

@media (max-width: 530px) {
  .login {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    margin-top: 4rem;
  }
}
</style>
