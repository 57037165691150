<template>
  <div class="home">
    <el-container class="comments-container">
      <el-card class="comment-card" v-for="comment in comments" :key="comment.id" shadow="always">
        <div class="comment-header">
          <el-avatar :src="comment.avatar" class="comment-avatar"></el-avatar>
          <div class="comment-user-info">
            <span class="username">{{ comment.username }}</span>
            <span class="date">{{ comment.date }}</span>
          </div>
        </div>
        <div class="comment-body">
          <p class="comment-text">{{ comment.comment }}</p>
        </div>
        <div class="comment-footer">
          <el-badge :value="comment.likes" class="item">
            <el-button  @click="goLogin" icon="el-icon-star-off" size="mini"></el-button>
          </el-badge>
          <el-badge :value="comment.evaluationas" class="item">
            <el-button  @click="goLogin" size="mini">评论</el-button>
          </el-badge>
          <el-badge is-dot class="item">
            <el-button  @click="goLogin" type="primary" size="mini" icon="el-icon-s-promotion"></el-button>
          </el-badge>
        </div>
      </el-card>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      comments: [
        {
          id: 3,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "小K_",
          date: "2024-12-28",
          comment: "非常实用的月卡，性价比很高，开通后体验真的很棒！",
          likes: 5,
          evaluationas: 203,
          liked: false,
        },
        {
          id: 4,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "大D",
          date: "2024-12-28",
          comment: "购买后立刻使用了，视频清晰不卡顿，果断推荐给朋友们。",
          likes: 10,
          evaluationas: 200,
          liked: true,
        },
        {
          id: 5,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "秋天的风",
          date: "2024-12-27",
          comment: "特别喜欢爱奇艺的月卡，连续剧更新很快，非常适合追剧党！",
          likes: 7,
          evaluationas: 189,
          liked: false,
        },
        {
          id: 6,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "秋名山车神",
          date: "2024-12-26",
          comment: "价格划算，还送了积分优惠，非常满意的一次购物体验！",
          likes: 3,
          evaluationas: 187,
          liked: false,
        },
        {
          id: 7,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "小灯泡",
          date: "2024-12-25",
          comment: "我购买的是音乐月卡，音质效果出色，听歌更有氛围了！",
          likes: 15,
          evaluationas: 189,
          liked: true,
        },
        {
          id: 8,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "我是大学生",
          date: "2024-12-25",
          comment: "作为学生党，这个价格非常友好，用来学习也很实用！",
          likes: 6,
          evaluationas: 191,
          liked: false,
        },
        {
          id: 9,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "强制网友",
          date: "2024-12-24",
          comment: "这是我买过最划算的月卡，期待更多类似的优惠活动！",
          likes: 9,
          evaluationas: 6,
          liked: true,
        },
        {
          id: 10,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "小丑J",
          date: "2024-12-23",
          comment: "体验感真的超级棒！电影和综艺都能随时观看，超值。",
          likes: 12,
          evaluationas: 7,
          liked: true,
        },
        {
          id: 11,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "哥谭蝙蝠侠",
          date: "2024-12-22",
          comment: "哈啰单车的月卡很好用，平时通勤成本大大降低了！",
          likes: 8,
          evaluationas: 36,
          liked: false,
        },
        {
          id: 12,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "大草莓",
          date: "2024-12-21",
          comment: "网易云音乐的月卡真的超值，歌单和推荐都很合心意。",
          likes: 14,
          evaluationas: 122,
          liked: true,
        },
        {
          id: 13,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "不高兴",
          date: "2024-12-20",
          comment: "开通后用起来非常方便，下次还会继续购买，满意度满分！",
          likes: 5,
          evaluationas: 122,
          liked: false,
        },
        {
          id: 14,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "晚霞风景",
          date: "2024-12-19",
          comment: "朋友推荐的，确实好用，现在看网盘大文件不再愁了。",
          likes: 7,
          evaluationas: 19,
          liked: false,
        },
        {
          id: 15,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "超市达人",
          date: "2024-12-18",
          comment: "Keep会员开通后跑步数据记录更全面，健康计划很实用！",
          likes: 10,
          evaluationas: 0,
          liked: true,
        },
        {
          id: 16,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "懒人",
          date: "2024-12-17",
          comment: "懒人听书月卡很划算，每晚睡前听书让我放松身心。",
          likes: 6,
          evaluationas: 0,
          liked: false,
        },
        {
          id: 17,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "吴青松",
          date: "2024-12-16",
          comment: "视频会员卡购买后使用非常方便，全家人都可以共享观看。",
          likes: 11,
          evaluationas: 0,
          liked: true,
        },
        {
          id: 18,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "房东vb",
          date: "2024-12-15",
          comment: "迅雷的会员卡用了之后下载速度飞快，真是太棒了！",
          likes: 8,
          evaluationas: 0,
          liked: false,
        },
        {
          id: 19,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "飞洒青菜",
          date: "2024-12-14",
          comment: "作业帮VIP功能很全，孩子学习成绩提升了不少，值了！",
          likes: 9,
          evaluationas: 6,
          liked: true,
        },
        {
          id: 20,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "代发反方向",
          date: "2024-12-13",
          comment: "购买体验很顺畅，权益开通也很快，客服的态度也非常好！",
          likes: 4,
          evaluationas: 101,
          liked: false,
        },
        {
          id: 21,
          avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          username: "动车小子",
          date: "2024-12-12",
          comment: "芒果TV的内容超级丰富，家里老人孩子都非常喜欢。",
          likes: 10,
          evaluationas: 119,
          liked: true,
        },
      ],
      // 添加更多评论对象
    };
  },
  methods: {
    likeComment(id) {
      const comment = this.comments.find((comment) => comment.id === id);
      if (comment) {
        comment.liked = !comment.liked;
        comment.likes += comment.liked ? 1 : -1;
      }
    },
    goLogin() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.comments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 0rem 2rem;
}

.comment-card {
  width: 100%;
  margin: 1rem;
  padding: 0.4rem 1rem;
}

.comment-header {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.comment-avatar {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.2rem;
}

.comment-user-info {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: bold;
  font-size: 1rem;
}

.date {
  color: #888;
  font-size: 0.8rem;
}

.comment-body {
  /* margin-bottom: 10px; */
}

.comment-text {
  font-size: 1rem;
  line-height: 1.2rem;
}

.comment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.like-button {
  display: flex;
  align-items: center;
}

.like-button span {
  /* margin-left: 5px; */
}
</style>
