<template>
  <div class="page">
    <div class="register">
      <el-form :model="form" ref="form" @submit.native.prevent>
        <!-- 手机号 -->
        <el-form-item prop="phone" :rules="phoneRules">
          <el-row :gutter="10">
            <el-col :span="16">
              <el-input v-model="form.phone" placeholder="请输入手机号">
                <template slot="prepend">
                  <i class="el-icon-mobile-phone"></i>
                </template>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button class="captcha-btn" @click="sendCaptcha">获取验证码</el-button>
            </el-col>
          </el-row>
        </el-form-item>

        <!-- 验证码 -->
        <el-form-item prop="code" :rules="codeRules">
          <el-input v-model="form.code" placeholder="请输入验证码">
            <template slot="prepend">
              <i class="el-icon-key"></i>
            </template>
          </el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item prop="password" :rules="passwordRules">
          <el-input v-model="form.password" type="password" placeholder="请输入密码" show-password>
            <template slot="prepend">
              <i class="el-icon-unlock"></i>
            </template>
          </el-input>
        </el-form-item>

        <!-- 确认密码 -->
        <el-form-item prop="confirmPassword" :rules="confirmPasswordRules">
          <el-input v-model="form.confirmPassword" type="password" placeholder="请确认密码" show-password>
            <template slot="prepend">
              <i class="el-icon-unlock"></i>
            </template>
          </el-input>
        </el-form-item>

        <!-- 昵称 -->
        <el-form-item prop="name" :rules="nameRules">
          <el-input v-model="form.name" type="password" placeholder="请输入昵称" show-password>
            <template slot="prepend">
              <i class="el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>

        <!-- 用户协议 -->
        <el-form-item>
          <el-checkbox v-model="form.agree" class="agreement">同意用户协议</el-checkbox>
        </el-form-item>

        <!-- 注册按钮 -->
        <el-form-item>
          <el-button type="primary" class="sbmit" @click="submitForm" :disabled="!form.agree">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      form: {
        phone: "",
        code: "",
        password: "",
        confirmPassword: "",
        agree: false,
        name: "",
      },
      // 手机号校验规则
      phoneRules: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        { pattern: /^[1][3-9][0-9]{9}$/, message: "手机号格式不正确", trigger: "blur" },
      ],
      // 验证码校验规则
      codeRules: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      // 密码校验规则
      passwordRules: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 6, max: 20, message: "密码长度应在6到20个字符之间", trigger: "blur" },
      ],
      // 确认密码校验规则
      confirmPasswordRules: [
        { required: true, message: "请确认密码", trigger: "blur" },
        { validator: this.validateConfirmPassword, trigger: "blur" },
      ],
      //昵称
      nameRules: [{ required: true, message: "请输入昵称", trigger: "blur" }],
    };
  },
  methods: {
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 提交表单
          this.$message.success("注册成功！");
          this.$router.push({ name: 'Login' });
        } else {
          this.$message.error("请检查表单填写是否正确");
          return false;
        }
      });
    },
    sendCaptcha() {
      // 模拟获取验证码
      this.$message.success("验证码已发送");
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  display: flex;
  justify-content: center;
}

.register {
  width: 26rem;
  height: 30rem;
  border-radius: 0.8rem;
  border: 1px solid #dac3c3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.05);
  margin-top: 8rem;
  padding: 2rem;
  box-sizing: border-box;

  .captcha-btn {
    width: 100%;
  }

  .agreement {
    margin-top: 1rem;
  }
  .sbmit {
    width: 100%;
    background-color: #16baaa;
  }
}
@media (max-width: 530px) {
  .register {
    width: 100%;
    border: none; /* 使用 'none' 来去除边框 */
    border-radius: 0; /* 去除圆角 */
    padding: 0; /* 去除内边距 */
    box-shadow: none; /* 去除阴影 */
    margin-top: 4rem;
  }
  .captcha-btn {
    width: 100%;
    padding: 12px 10px;  
  }
}
</style>
