import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/home.vue";
import Products from "@/views/products.vue";
import Register from "@/views/register.vue";
import Login from "@/views/login.vue";
import userEvaluation from "@/views/userEvaluation.vue";
import uploadProducts from "@/views/uploadProducts.vue";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/", name: "Home", component: Home },
    { path: "/products", name: "Products", component: Products },
    { path: "/register", name: "Register", component: Register },
    { path: "/login", name: "Login", component: Login },
    { path: "/user/evaluation", name: "userEvaluation", component: userEvaluation},
    { path: "/upload/products", name: "uploadProducts", component: uploadProducts}
  ],
});
