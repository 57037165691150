<template>
  <div class="upload-product">
    <div class="steps">
      <el-steps :active="state" finish-status="success" simple style="margin-top: 20px">
        <el-step title="开始"></el-step>
        <el-step title="上传"></el-step>
        <el-step title="发布"></el-step>
      </el-steps>
    </div>
    <div v-show="state === 1">
      <el-empty description="点击开始上传权益产品"> <el-button type="primary" @click="handleStep">上传产品</el-button></el-empty>
    </div>
    <div v-show="state === 2">
      <el-form ref="uploadForm" :model="form" :rules="rules" label-width="80px" label-position="left">
        <el-form-item label="权益名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入权益名称"></el-input>
        </el-form-item>
        <el-form-item label="权益Logo" prop="logo">
          <el-upload action="#" style="text-align: left" list-type="picture-card" :limit="1" :auto-upload="false">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                  <i class="el-icon-download"></i>
                </span>
                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="form.logo" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item label="价格" prop="price">
          <el-input v-model.number="form.price" placeholder="请输入价格"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSubmit">开始上传</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: 1,
      form: {
        name: "",
        logo: "",
        price: null,
      },
      dialogVisible: false,
      disabled: false,
      fileList: [], // 存储上传的文件列表
      rules: {
        name: [{ required: true, message: "请输入权益名称", trigger: "blur" }],
        // logo: [{ required: true, message: "请上传权益Logo", trigger: "change" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
        console.log('上传权益',file)
      this.form.logo = file.url;
     
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleSubmit() {
      this.$refs.uploadForm.validate((valid) => {
        if (valid) {
          this.$message.error("请先登录再进行操作");
          this.$router.push({ path: "/login" });
        } else {
          this.$message.error("请完善表单内容");
        }
      });
    },
    handleStep(){
        this.state = 2;
    },
  },
};
</script>

<style scoped>
.upload-product {
  max-width: 800px;
  margin: 0px auto;
  padding: 1rem;
}

.steps {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.upload-card {
  padding: 20px;
}

.upload-logo {
  margin-bottom: 20px;
}
.picture-card {
  text-align: left;
}
::v-deep .el-form-item__label:before {
  margin-left: -10px !important;
}
</style>
